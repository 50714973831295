import { useForm } from 'react-hook-form'
import FormInput from '../../../../../components/formInput'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import moment, { min } from 'moment'
import { toast } from 'react-toastify'
import { useQuery, useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { LocationApi } from '../../../../../services/external/cep'
import { validate as isCPFValid } from 'cpf-check';
import { cadastrarInscricaoChamadaPublica, listarUnidadesChamadaPublica } from '../../../../../services/processos/chamadaPublica'
import urlsChamadaPublica from '../../urlsChamadaPublica'
import '../style.css';
import { getUnidades } from '../../../../../services/unidades'
import Select from 'react-select';
import api from '../../../../../services'
import Alert from '@mui/material/Alert';
import { getAlunosCpf } from '../../../../../services/alunos'



const ChamadaPublicaFormularioPage = (validacao) => {

const navigate = useNavigate()
const { current: locationApi } = useRef(new LocationApi());
const { current: controller } = useRef(new AbortController());
const [unidade, setUnidade] = useState(0)
const [exibeEstudanteResponsavelLegal, setExibeEstudanteResponsavelLegal] = useState(false)
const [exibeMensagemDeficiencia, setExibeMensagemDeficiencia] = useState(0)
const [exibeDocumentosComprovante, setExibeDocumentosComprovante] = useState(0)
const [erroDataNascimento, setErroDataNascimento] = useState('')
const [dataUnidades, setDataUnidades] = useState([])
const [validaDataNascimento, setValidaDataNascimento] = useState(true)
const [unidades, setUnidades] = useState([])

const [exibeAvisoEndereco, setExibeAvisoEndereco] = useState(0)
const [exibeAvisoCpf, setExibeAvisoCpf] = useState(0)
const [exibeAvisoBeneficios, setExibeAvisoBeneficios] = useState(0)

const [formularioInvalido, setFormularioInvalido] = useState(true)

const [exibeTempoTrabalho, setExibeTempoTrabalho] = useState(0)


const onMenuOpen = () => setIsOpen(true);
const onMenuClose = () => setIsOpen(false);
const [isOpen, setIsOpen] = useState(false);

const { isLoading: loadingUnidade} = useQuery('getUnidades', () => getUnidades(), {
  enabled: true,
  retry: true,
  onSuccess: (data) => {
    var dados = [{value: 0, label: 'SELECIONE'}];
    data.forEach(item => {
      dados.push({ value: item.id, label: item.nome })
    });

    setUnidades(dados);
  }
})

var dataCorte = moment("2024-03-28");
var dataCorteMaioridade = moment("2024-03-28")

const escolaridades = [
  { value: 0, text: '' },
  { value: 1, text: '1º ANO' },
  { value: 2, text: '2º ANO' },
  { value: 3, text: '3º ANO' },
  { value: 4, text: '4º ANO' },
  { value: 5, text: '5º ANO' },
  { value: 6, text: '6º ANO' },
  { value: 7, text: '7º ANO' },
  { value: 8, text: '8º ANO' },
  { value: 9, text: '9º ANO' },
  { value: 10, text: 'CRECHE I' },
  { value: 11, text: 'CRECHE II' },
  { value: 12, text: 'CRECHE III' },
  { value: 13, text: 'CRECHE IV' },
  //{ value: 14, text: 'EJA' },
  // { value: 15, text: 'FASE I' },
  // { value: 16, text: 'FASE II' },
  // { value: 17, text: 'FASE III' },
  // { value: 18, text: 'FASE IV' },
  // { value: 19, text: 'FASE IX' },
  // { value: 20, text: 'FASE V' },
  // { value: 21, text: 'FASE VI' },
  // { value: 22, text: 'FASE VII' },
  // { value: 23, text: 'FASE VIII' },
  { value: 24, text: 'PRÉ I' },
  { value: 26, text: 'PRÉ II' },
  // { value: 27, text: 'MULTISSERIADA' },
  // { value: 28, text: 'ENSINO MÉDIO' },
]

const deficiencias = [
  { value: 0, text: '' },
    { value: 1, text: 'DEFICIENCIAS MÚLTIPLAS' },
    { value: 2, text: 'DEFICIÊNCIA INTELECTUAL' },
    { value: 3, text: 'DEFICIÊNCIA VISUAL' },
    { value: 4, text: 'DEFICIÊNCIA AUDITIVA' },
    { value: 5, text: 'DEFICIÊNCIA FÍSICA' },
    { value: 6, text: 'TRANSTORNO DO ESPECTRO AUTISTA (TEA)' },
    { value: 7, text: 'ALTAS HABILIDADES' },
];


const tempoTrabalho = [
        { value: 0, text: '' },
        { value: 1, text: 'SIM' },
        { value: 2, text: 'NÃO' }   
]

const schoolOptions = [
  { value: 0, text: '' },
  { value: 2, text: 'COORDENADORIA REGIONAL BAIXADA LITORÂNEA' },
  { value: 5, text: 'CRECHE MUNICIPAL COMUNITÁRIA DONA CHICA' },
  { value: 6, text: 'CRECHE MUNICIPAL COMUNITÁRIA PROFESSORA TIA FÁTIMA' },
  { value: 7, text: 'CRECHE MUNICIPAL TIA MÁRCIA' },
  { value: 52, text: 'ESCOLA DE ARTES MUNICIPAL DIRETORA LUÍZA MARIA LEAL MENDES' },
  { value: 8, text: 'ESCOLA MUNICIPAL ANTÔNIO RODRIGUES DOS SANTOS' },
  { value: 9, text: 'ESCOLA MUNICIPAL ANTÔNIO VAZ DA SILVA' },
  { value: 10, text: 'ESCOLA MUNICIPAL AURELINO MARTINS DOS SANTOS' },
  { value: 11, text: 'ESCOLA MUNICIPAL BARNABÉ MARIANO DE SOUZA' },
  { value: 12, text: 'ESCOLA MUNICIPAL CARLOTA ROCHA DA SILVA' },
  { value: 13, text: 'ESCOLA MUNICIPAL DE EDUCAÇÃO ESPECIAL PEDRO PAULO LOBO DE ANDRADE' },
  { value: 26, text: 'ESCOLA MUNICIPAL DE EDUCAÇÃO INFANTIL GRACINÉA RODRIGUES DE SOUZA' },
  { value: 27, text: 'ESCOLA MUNICIPAL DR. PLÍNIO DE ASSIS TAVARES' },
  { value: 28, text: 'ESCOLA MUNICIPAL DULCE JOTTA DE SOUZA' },
  { value: 29, text: 'ESCOLA MUNICIPAL ELÍZIO HENRIQUE DE PAIVA' },
  { value: 30, text: 'ESCOLA MUNICIPAL ELÍZIO IGNÁCIO RANGEL' },
  { value: 31, text: 'ESCOLA MUNICIPAL FLONETE ALEXANDRINO DA SILVA' },
  { value: 32, text: 'ESCOLA MUNICIPAL FRANCISCO PAES DE CARVALHO FILHO' },
  { value: 14, text: 'ESCOLA MUNICIPALIZADA ADALGIZA DA SILVA LOBO' },
  { value: 15, text: 'ESCOLA MUNICIPALIZADA CAPITÃO COSTA' },
  { value: 16, text: 'ESCOLA MUNICIPALIZADA ELÍZIO DA COSTA MOREIRA' },
  { value: 17, text: 'ESCOLA MUNICIPALIZADA JOSÉ GUIMARÃES' },
  { value: 18, text: 'ESCOLA MUNICIPALIZADA LUCINDA FRANCISCONE DE MEDEIROS' },
  { value: 19, text: 'ESCOLA MUNICIPALIZADA MANOEL MARTINS TEIXEIRA' },
  { value: 20, text: 'ESCOLA MUNICIPALIZADA MANOEL MORAES DA SILVA' },
  { value: 21, text: 'ESCOLA MUNICIPALIZADA PAINEIRA' },
  { value: 22, text: 'ESCOLA MUNICIPALIZADA PAULO ROBERTO MARINHO' },
  { value: 23, text: 'ESCOLA MUNICIPALIZADA PEQUIÁ' },
  { value: 24, text: 'ESCOLA MUNICIPALIZADA RETIRO' },
  { value: 25, text: 'ESCOLA MUNICIPALIZADA VITAL BRASIL' },
  { value: 33, text: 'ESCOLA MUNICIPAL JAMILA MOTA DA SILVA' },
  { value: 34, text: 'ESCOLA MUNICIPAL JARDIM PRIMAVERA' },
  { value: 35, text: 'ESCOLA MUNICIPAL JOSÉ TEIXEIRA PAULO' },
  { value: 36, text: 'ESCOLA MUNICIPAL LUÍZA TERRA DE ANDRADE' },
  { value: 37, text: 'ESCOLA MUNICIPAL PROFESSORA CAROLINA NAZARETH TEIXEIRA PINHEIRO' },
  { value: 38, text: 'ESCOLA MUNICIPAL PROFESSORA DULCINDA JOTTA MENDES' },
  { value: 39, text: 'ESCOLA MUNICIPAL PROFESSORA MARIA CELESTE DE CAMPOS' },
  { value: 40, text: 'ESCOLA MUNICIPAL PROFESSORA MARIA DA GLÓRIA DOS SANTOS MOTTA' },
  { value: 41, text: 'ESCOLA MUNICIPAL PROFESSORA MIRIAN ALVES DE MACEDO GUIMARÃES - CÍVICO MILITAR' },
  { value: 42, text: 'ESCOLA MUNICIPAL QUILOMBOLA DONA ROSA GERALDA DA SILVEIRA' },
  { value: 43, text: 'ESCOLA MUNICIPAL RUBEM ARRUDA' },
  { value: 44, text: 'ESCOLA MUNICIPAL SÃO FRANCISCO DE ASSIS' },
  { value: 45, text: 'ESCOLA MUNICIPAL VIDAL DE NEGREIROS' },
  { value: 46, text: 'ESCOLA MUNICIPAL VINHATEIRO' },
  { value: 3, text: 'PREFEITURA MUNICIPAL DE SÃO PEDRO DA ALDEIA' },
  { value: 1, text: 'SECRETARIA ESTADUAL DE EDUCACAO DO RIO DE JANEIRO' },
  // { value: 4, text: 'SECRETARIA MUNICIPAL DE EDUCAÇÃO' },
  // { value: 51, text: 'SEM UNIDADE' },
  // { value: 53, text: 'TRANSFERÊNCIA EXTERNA' },
];


const {register, handleSubmit, getValues, watch, setValue, formState: { isValid, errors }} = useForm({
    mode: 'onChange',
    defaultValues: {
        nome: '',
        escolaridade: 0,
        dataNascimento: '',
      
        cpf: '',
        cartaoDoSus: '',
        nis: '',
        deficiente: 0,
        deficiencia: 0,
        estudanteDaRede: 0,
        estudanteDaRedeUnidade: 0,
        filiacao1: '',
        filiacao2: '',
        responsavelNomeCompleto: '',
        responsavelDataNascimento: '',
        responsavelCpf: '',
        responsavelRg: '',
        responsavelEmail: '',
        responsavelTelefone: '',
        reponsavelParentesco: '',
        responsavelDeficiente: 0,
        responsavelDeficiencia: 0,
        cep: '',
        estado: '',
        cidade: '',
        bairro: '',
        logradouro: '',
        numero: '',
        complemento: '',
        possuiIrmao: 0,
        escolaridadeIrmao: 0,
        nomeIrmao: '',
        beneficiarioProgramaSocial: 0,
        tempoTrabalho: 0,
        monoparental: 0,
        opcao1: 0,
        opcao2: 0,
        opcao3: 0

    }
    })

    const { refetch: refetchUnidades} = useQuery(
        'listarUnidadesChamadaPublica',
        () => listarUnidadesChamadaPublica(watch('bairro'), watch('escolaridade')),
        {
          retry: 1,
          enabled: watch('bairro').length > 0,
          onSuccess: data => {
            console.log(data)
            setDataUnidades(data)
          }
        }
      )




      useEffect(() => {
        const escolaridade = watch('escolaridade')
        const bairro = watch('bairro')
        const cidade = watch('cidade')
        const cep = watch('cep')

        console.log(bairro)

        if (escolaridade == 10 || escolaridade == 11 || escolaridade == 12 || escolaridade == 13){
          setExibeTempoTrabalho(1)
        }

        if(cidade == 'São Pedro da Aldeia' && escolaridade > 0 && cep.length == 9 && bairro.length > 0){
          refetchUnidades()
        }

      }, [watch('bairro'), watch('escolaridade')])
      
      useEffect(() => {
        const checkCpf = async () => {
          const cpf = watch('cpf'); // Assuming 'watch' is correctly defined in your component's context
    
          if (cpf.length === 14 && isCPFValid(cpf) && validacao.validacao === true) {
            try {
              const response = await getAlunosCpf(cpf.replace(/\D/g, ''));
              const id = response?.data;
    
              if (id.length > 0) {
                toast.error('Esse CPF pertence a um aluno da rede.'); // Assuming 'toast' is correctly defined and imported
                setExibeAvisoCpf(1); // Assuming 'setExibeAvisoCpf' is correctly defined
              }
            } catch (error) {
              // Handle any errors here, such as network issues or API failures
              // console.error('Error fetching data: ', error);
            }
          }
        };
    
        checkCpf();
      }, [watch, watch('cpf')]);


      useEffect(() => {
        const possuiIrmao = watch('possuiIrmao')
        const unidade = watch('opcao1')

        if(unidade != 0){
            setUnidade(unidade)
        }
        if(possuiIrmao == 1){

        }

      }, [watch('possuiIrmao')])


    function validateFields (){

      const nome = watch('nome')
      const escolaridade = watch('escolaridade')
      const dataNascimento = watch('dataNascimento')
      const cpf = watch('cpf');
      const deficiente = watch('deficiente')
      const deficiencia = watch('deficiencia')
      const estudanteDaRede = watch('estudanteDaRede')
      const filiacao1 = watch('filiacao1')

      const responsavelNomeCompleto = watch('responsavelNomeCompleto');
      const responsavelDataNascimento = watch('responsavelDataNascimento');
      const responsavelCpf = watch('responsavelCpf');
      const responsavelRg = watch('responsavelRg');
      const responsavelEmail = watch('responsavelEmail');
      const responsavelTelefone = watch('responsavelTelefone');
      const reponsavelParentesco = watch('reponsavelParentesco');
      const responsavelDeficiente = watch('responsavelDeficiente');
      const responsavelDeficiencia = watch('responsavelDeficiencia');

 
      const cep = watch('cep');
      const numero = watch('numero');

      
      const possuiIrmao = watch('possuiIrmao');
      const escolaridadeIrmao = watch('escolaridadeIrmao');
      const nomeIrmao = watch('nomeIrmao');
      const beneficiarioProgramaSocial = watch('beneficiarioProgramaSocial');
      const tempoTrabalho = watch('tempoTrabalho');
      const monoparental = watch('monoparental');
      const opcao1 = watch('opcao1');
      const opcao2 = watch('opcao2');
      const opcao3 = watch('opcao3');
      const termoDeclaracao = watch('termoDeclaracao');
            
      setFormularioInvalido(true)

      if(nome == ''){
        return toast.error('O campo nome é obrigatório.')
      }

      if(escolaridade == 0){
        return toast.error('O campo escolaridade é obrigatório.')
      }
      if(dataNascimento == ''){
        return toast.error('O campo data de nascimento é obrigatório.')
      }

      if(erroDataNascimento.length > 0){
       return toast.error('O campo data de nascimento não é válido, utilize a tabela ao topo como base.')
      }

      if (!isCPFValid(cpf)) {
        return toast.error('O campo CPF é inválido.');
      }

      

      if(deficiente == 0){
        return toast.error('O campo possui alguma deficiência é obrigatório.')
      }

      if(deficiente == 1 && deficiencia == 0){
        return toast.error('O campo deficiência é obrigatório.')
      }

      if(estudanteDaRede == 0){
        return toast.error('O campo já é estudante da rede é obrigatório.')
      }

      if(estudanteDaRede == 1 && unidade == 0){
        return toast.error('O campo já é estudante da rede unidade é obrigatório.')
      }

      if(filiacao1 == ''){
        return toast.error('O campo filiação 1 é obrigatório.')
      }

      if (responsavelNomeCompleto === '') {
        return toast.error('O campo responsável nome completo é obrigatório.');
      }
      
      if (responsavelDataNascimento === '') {
        return toast.error('O campo responsável data de nascimento é obrigatório.');
      }
      
      if (!isCPFValid(responsavelCpf)) {
        return toast.error('O campo responsável CPF é inválido.');
      }
      
      if (responsavelRg === '') {
        return toast.error('O campo responsável RG é obrigatório.');
      }
      
      if (responsavelEmail === '') {
        return toast.error('O campo responsável e-mail é obrigatório.');
      }
      
      if (responsavelTelefone === '') {
        return toast.error('O campo responsável telefone é obrigatório.');
      }
      
      if (reponsavelParentesco === '') {
        return toast.error('O campo responsável parentesco é obrigatório.');
      }
      
      if (responsavelDeficiente === '') {
        return toast.error('O campo responsável deficiente é obrigatório.');
      }
      
      if (responsavelDeficiente === 1 && responsavelDeficiencia === 0) {
        return toast.error('O campo responsável deficiência é obrigatório.');
      }

      if (cep === '') {
        return toast.error('O campo CEP é obrigatório.');
      }

      if (numero === '') {
        return toast.error('O campo número é obrigatório.');
      }

      

      if (beneficiarioProgramaSocial === 0) {
        return toast.error('O campo beneficiário do Bolsa Família ou cadastro no CadÚnico é obrigatório.');
        
      }

      

      if (tempoTrabalho === 0 && exibeTempoTrabalho == 1){
        return toast.error('O campo responsável legal está empregado é obrigatório.');
      }

      if (monoparental === 0){
        return toast.error('O campo possui filiação monoparental é obrigatório.');
      }

      if (opcao1 === 0){
        return toast.error('O campo opção 1 é obrigatório.');
      }

      if (opcao2 != 0 && opcao1 == opcao2){
        return toast.error('Opção 2 não pode ser igual a opção 1.');
      }

      if (opcao3 != 0  && (opcao1 == opcao3 || opcao2 == opcao3)){
        return toast.error('Opção 3 não pode ser igual a opção 1 / opção 2.');
      }

      if (possuiIrmao === 0) {
        return toast.error('O campo possui irmão/irmã na primeira escola pretendida é obrigatório.');
      }

      if (possuiIrmao === 1){
        if (escolaridadeIrmao === 0) {
          return toast.error('O campo escolaridade irmão é obrigatório.');
        }
  
        if (nomeIrmao === '') {
          return toast.error('O campo nome do irmão é obrigatório.');
        }
      }

      if (termoDeclaracao === false){
        return toast.error('Concorde com os termos para enviar o formulário.');
      }

      if (cpf == responsavelCpf){
        return toast.error('O CPF do aluno não pode ser igual do responsável.');
      }

      setFormularioInvalido(false)
      
    }
      
    

      useEffect(() => {
        const tempoTrabalho = watch('tempoTrabalho')

        console.log(tempoTrabalho)

        
        if(tempoTrabalho > 0 && tempoTrabalho != 2){
          setExibeDocumentosComprovante(1)
        }
        else{
          setExibeDocumentosComprovante(0)
        }
        


      }, [watch('tempoTrabalho')])

      useEffect(() => {
        const beneficiarioProgramaSocial = watch('beneficiarioProgramaSocial')

        console.log(tempoTrabalho)

        
        if(beneficiarioProgramaSocial == 1 || beneficiarioProgramaSocial == 2){
          setExibeAvisoBeneficios(1)
        }
        else{
          setExibeAvisoBeneficios(0)
        }
        


      }, [watch('beneficiarioProgramaSocial')])

      useEffect(() => {
        const beneficiarioProgramaSocial = watch('beneficiarioProgramaSocial')

        console.log(tempoTrabalho)

        
        if(beneficiarioProgramaSocial == 1 || beneficiarioProgramaSocial == 2){
          setExibeAvisoBeneficios(1)
        }
        else{
          setExibeAvisoBeneficios(0)
        }
        


      }, [watch('beneficiarioProgramaSocial')])

      const isDateBetween = (date, startDate, endDate) => {

        const checkDate = new Date(date);
        const checkStartDate = new Date(startDate);
        const checkEndDate = new Date(endDate);
      
        return checkDate >= checkStartDate && checkDate <= checkEndDate;
      };

      const isOldEnough = (birthdate) => {
        // Convert birthdate string to a Date object
        const birthdateDate = new Date(birthdate);
    
        // Calculate today's date
        const today = new Date('2023-03-31');
    
        // Calculate the age
        const age = today.getFullYear() - birthdateDate.getFullYear();
    
        // Check if the person is at least 15 anos old
        return age >= 15;
      };

      useEffect(() => {
        const dataNascimento = watch('dataNascimento')
        const escolaridade = watch('escolaridade')

        const minDate = new Date('1900-01-01');
        const refrenceDate = new Date(dataNascimento)


        if(dataNascimento != '' && refrenceDate > minDate){

          // setErroDataNascimento('')

          setErroDataNascimento('')
          setValidaDataNascimento(false)


            if (escolaridade == 10 && isDateBetween(refrenceDate, new Date('2023-04-01'), new Date('2023-09-30')) == false ){
              setErroDataNascimento('Data Inválida. Permitido Creche I: de 06 (seis) a 11 (onze) meses.');
              toast.error('Data Inválida. Permitido Creche I: de 06 (seis) a 11 (onze) meses.');
              setFormularioInvalido(true)
            }
            if (escolaridade == 11 && isDateBetween(refrenceDate, new Date('2022-04-01'), new Date('2023-03-31'))== false){
              setErroDataNascimento('Data Inválida. Creche II: de 1 (um) ano a 1 (um) ano e 11 (onze) meses.');
              setValidaDataNascimento(true)
              toast.error('Data Inválida. Creche II: de 1 (um) ano a 1 (um) ano e 11 (onze) meses.');
              setFormularioInvalido(true)
            }
            if (escolaridade == 12 && isDateBetween(refrenceDate, new Date('2021-04-01'), new Date('2022-03-31'))== false){
              setErroDataNascimento('Data Inválida. Creche III: de 2 (dois) anos a 2 (dois) anos e 11 (onze) meses.');
              toast.error('Data Inválida. Creche III: de 2 (dois) anos a 2 (dois) anos e 11 (onze) meses.');
              setFormularioInvalido(true)
              setValidaDataNascimento(true)
            }
            if (escolaridade == 13 && isDateBetween(refrenceDate, new Date('2020-04-01'), new Date('2021-03-31'))== false){
              setErroDataNascimento('Data Inválida. Creche IV: de 3 (três) anos a 3 (três) anos e 11 (onze) meses.');
              toast.error('Data Inválida. Creche IV: de 3 (três) anos a 3 (três) anos e 11 (onze) meses.');
              setFormularioInvalido(true)
            }
            if (escolaridade == 24 && isDateBetween(refrenceDate, new Date('2019-04-01'), new Date('2020-03-31'))== false){
              setErroDataNascimento('Data Inválida. Pré I: de 4 (quatro) anos a 4 (quatro) anos e 11 (onze) meses.');
              toast.error('Data Inválida. Pré I: de 4 (quatro) anos a 4 (quatro) anos e 11 (onze) meses.');
              setFormularioInvalido(true)
              setValidaDataNascimento(true)
            }
            if (escolaridade == 26 && isDateBetween(refrenceDate, new Date('2018-04-01'), new Date('2019-03-31'))== false){
              setErroDataNascimento('Data Inválida. Pré II: de 5 (cinco) anos a 5 (cinco) anos e 11 (onze) meses');
              toast.error('Data Inválida. Pré II: de 5 (cinco) anos a 5 (cinco) anos e 11 (onze) meses.');
              setFormularioInvalido(true)
              setValidaDataNascimento(true)
            }
            if (escolaridade == 1 && isDateBetween(refrenceDate, new Date('2006-04-01'), new Date('2018-03-31'))== false){
              setErroDataNascimento('Data Inválida. 1 Ano: a partir de 6 anos de idade completos.');
              toast.error('Data Inválida. 1 Ano: a partir de 6 anos de idade completos.');
              setFormularioInvalido(true)
            }

            if (escolaridade == 15 && isOldEnough(refrenceDate)){
              setErroDataNascimento('Data Inválida. EJA: a partir de 15 anos de idade completos.');
              toast.error('Data Inválida. EJA: a partir de 15 anos de idade completos.');
              setFormularioInvalido(true)
              setValidaDataNascimento(true)
            }

         
        }
        


      }, [watch('dataNascimento'), watch('escolaridade')])

      useEffect(() => {

        const cep = zipcodeMask(watch('cep')).replace(/\D/, '')
        handlAutoCompleteForm(cep)
      }, [watch('cep')])

      const handlAutoCompleteForm = useCallback(
       
        async (cep) => {
          if (cep.length < 8) return;
          try {
            const data = await locationApi.searchByCep(cep, controller.signal);
            let cidade = data?.data.data.cidade;
            let uf = data?.data.data.uf;
            let bairro = data?.data.data.bairro;
            let logradouro = data?.data.data.logradouro;

            if (data.status === 400) {
              toast.warn(data?.data.message);
            }

            if(cidade != 'São Pedro da Aldeia'){
              setExibeAvisoEndereco(1)
            }

            if(cidade == 'São Pedro da Aldeia'){
              setExibeAvisoEndereco(0)
            }
            
            setValue('bairro', bairro, {
              shouldValidate: true,
            });
            setValue('cidade', cidade, {
              shouldValidate: true,
            });
            setValue('estado', uf, {
              shouldValidate: true,
            });
            setValue('logradouro', logradouro, {
              shouldValidate: true,
            });
          } catch (error) {
            toast.error('Não foi possível carregar o endereço, cep não encontrado na base de dados do correio.');
          }
        },
        [setValue]
      );

      // eslint-disable-next-line consistent-return
      function zipcodeMask(value) {
        if (!value) return '';
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d{5})(\d{3})$/, '$1-$2');
        return value;
      }

      const { mutate: mutatePostForm } = useMutation(cadastrarInscricaoChamadaPublica, {
        onSuccess: data => {
          console.log(data)
          if (data == undefined || data == false) {
             toast.error('Ocorreu um erro no envio, favor tentar novamente mais tarde.')
          }
          if (data != false && data != undefined) {
            toast.success('Inscrição enviada com sucesso.');
            navigate(urlsChamadaPublica.visualizacao + data.id)
          }
    
    
        },
        onError: data => {
          toast.error('Ocorreu um erro no envio, favor tentar novamente mais tarde.')
        }
      })

      const onSubmit = (values) => {

        validateFields()

        if(formularioInvalido == false){
          const confirm = window.confirm('Está de acordo com os dados enviados?')
          if (confirm) {
            mutatePostForm(values)
          }
          // Lógica de envio dos dados
        
          console.log('Dados do formulário:', values);
        }

       
      };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      padding: '24px 48px',
    }}>
      <img src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`} width="200" height="150" style={{ display: 'flex', alignSelf: 'center' }} />
      <h3 style={{ margin: '15px', alignSelf: 'center' }}>SECRETARIA DE EDUCAÇÃO - PREFEITURA MUNICIPAL DE SÃO PEDRO</h3>
      <h2 style={{ margin: '15px', alignSelf: 'center' }}>CHAMADA PÚBLICA | ALUNOS 2024</h2>

      {/* <img src='/assets/images/idades.png' width="1050" height="400" style={{ display: 'flex', alignSelf: 'center' }} ></img> */}
      <table>

        
  <tr>
    <th>Turma</th>
    <th>Nascidos A Partir De</th>
    <th>Nascidos Até</th>
    <th>Idade Base A Partir De</th>
    <th>Idade Base A Partir De</th>
  </tr>
  <tr>
    <td>Creche I</td>
    <td>01/04/2023</td>
    <td>30/09/2023</td>
    <td>6 meses</td>
    <td>11 meses</td>
  </tr>
  <tr>
    <td>Creche II</td>
    <td>01/04/2022</td>
    <td>31/03/2023</td>
    <td>1 ano</td>
    <td>1 ano e 11 meses</td>
  </tr>
  <tr>
    <td>Creche III</td>
    <td>01/04/2021</td>
    <td>31/03/2022</td>
    <td>2 anos</td>
    <td>2 anos e 11 meses</td>
  </tr>
  <tr>
    <td>Creche IV</td>
    <td>01/04/2020</td>
    <td>31/03/2021</td>
    <td>3 anos</td>
    <td>3 anos e 11 meses</td>
  </tr>
  <tr>
    <td>Pré I</td>
    <td>01/04/2019</td>
    <td>31/03/2020</td>
    <td>4 anos</td>
    <td>4 anos e 11 meses</td>
  </tr>
  <tr>
    <td>Pré II</td>
    <td>01/04/2018</td>
    <td>31/03/2019</td>
    <td>5 anos</td>
    <td>5 anos e 11 meses</td>
  </tr>
  <tr>
    <td>1º Ano</td>
    <td>01/04/2006</td>
    <td>31/03/2018</td>
    <td>6 anos</td>
    <td>17 anos e 11 meses</td>
  </tr>
</table>

<Alert variant="filled" severity="warning" style={{marginBottom: 5, marginTop: 20, fontSize: 14}}>Inscrição disponível apenas para alunos que não são da rede de São Pedro da Aldeia.</Alert>

      {/* <h4 style={{textAlign: 'center'}}>DATA DE CORTE - REFERÊNCIA 31/03/2024</h4>
      <ul style={{textAlign: 'center', listStyleType: 'none'}}>
                    <li style={{textTransform: 'uppercase'}}>Creche I: de 06 (seis) a 11 (onze) meses</li>
                    <li style={{textTransform: 'uppercase'}}>Creche II: de 1 (um) ano a 1 (um) ano e 11 (onze) meses</li>
                    <li style={{textTransform: 'uppercase'}}>Creche III: de 2 (dois) anos a 2 (dois) anos e 11 (onze) meses</li>
                    <li style={{textTransform: 'uppercase'}}>Creche IV: de 3 (três) anos a 3 (três) anos e 11 (onze) meses</li>
                    <li style={{textTransform: 'uppercase'}}>Pré I: de 4 (quatro) anos a 4 (quatro) anos e 11 (onze) meses</li>
                    <li style={{textTransform: 'uppercase'}}>Pré II: de 5 (cinco) anos a 5 (cinco) anos e 11 (onze) meses</li>
                    <li style={{textTransform: 'uppercase'}}>1 Ano: A partir de 6 anos</li>
                </ul> */}
      {/* <h2 style={{ margin: '15px', alignSelf: 'center' }}>SOMENTE PARA ALUNOS DAS UNIDADES DE ENSINO QUE NÃO ATENDEM O SEGMENTO NECESSÁRIO À MATRÍCULA 2024.</h2> */}
      <div className="row" style={{marginTop: 20}}>
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid">
           
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                <form id="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body">
                <h4 style={{marginTop: '40px', marginBottom: '1px'}}>INFORMAÇÕES DO(A) ALUNO(A)</h4>
                     <hr
  style={{
    background: '#000',
    color: '#000',
    borderColor: '#00',
    height: '1px',
  }}
/>
                <div className="row">
                <div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="nome"
                    label="Nome"
                    required={true}
                    />
                </div>
                <div className="col-md-4">
              <div className="form-group">
                <label>Escolaridade<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                <select  className="form-select" id="escolaridade" {...register("escolaridade", { valueAsNumber: true, required: true })}>
                    {escolaridades.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.text}
                    </option>
                    ))}
                </select>
              </div>
            </div>
                <div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    type='date'
                    atribute="dataNascimento"
                    label="Data Nascimento"
                    />
                    {erroDataNascimento.length > 0 && (
                    <div style={{color: 'red'}} className="error-message">{erroDataNascimento}</div>
                )}
                </div>
               
                
                </div>

                <div className="row" style={{marginTop: 20}}>
                <div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="cpf"
                    mask="999.999.999-99"
                    label="CPF"
                    required={true}
                    />
                </div>
                <div className="col-md-4">
                    <FormInput
                    register={register}
                    // errors={errors}
                    atribute="cartaoDoSus"
                    label="Cartão do Sus"
                    required={false}
                    mask="9999.9999.9999.9999"
                    />

                </div>
                <div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="nis"
                    label="NIS"
                    required={false}
                    mask="999.9999.999.9"
                    />
                </div>
                </div>

                <div className="row" style={{marginTop: 20}}>

                <div className='col-md-6'>
            <div className="form-group">
              <label htmlFor="deficiente">Possui Alguma Deficiência?<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select className="form-select" id="deficiente" {...register("deficiente", { valueAsNumber: true })} defaultValue={0}>
                <option value={0}></option>
                <option value={1}>SIM</option>
                <option value={2}>NÃO</option>
              </select>
            </div>
          </div>

          {
            watch('deficiente') === 1  && (
              
              <>
                <div className='col-md-6'>
            <div className="form-group">
                    <label htmlFor="deficiencia">Deficiência<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                    <select className="form-select" id="deficiencia" {...register("deficiencia", { required: watch('deficiente') === 1 ? true : false, valueAsNumber: true })} defaultValue={0}>
                    {deficiencias.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.text}
                    </option>
                    ))}
              </select>

            </div>
          </div>
          

          </>
            )
            }

{
            watch('deficiente') === 1  && (
              
              <>

              <div className="row" style={{marginTop: 20, color: 'red', }}>
        <h2>Atenção:</h2>
          <p>
          Necessário comprovação de laudo médico atualizado que comprove deficiência (no ato de efetivação da matrícula).
          </p>
          </div>
          </>
            )
            }
                </div>

                <div className="row" style={{marginTop: 20}}>
               
            <div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="filiacao1"
                    label="Filiação 1"
                    required={true}
                    />
                </div>
                <div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="filiacao2"
                    label="Filiação 2"
                    required={false}
                    />
                </div>
                <div className="col-md-4">
              <div className="form-group">
                <label>Já é estudante da rede municipal?<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                <select  className="form-select" id="estudanteDaRede" {...register("estudanteDaRede", { valueAsNumber: true, required: true })} defaultValue={0}>
                <option value={0}></option>
                <option value={1}>SIM</option>
                <option value={2}>NÃO</option>
                </select>
              </div>
            </div>
                    </div>

                    {
            watch('estudanteDaRede') === 1  && (
              
              <>

                    <div className="row" style={{marginTop: 20}}>
                    <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="estudanteDaRedeUnidade" className="form-label">
              Unidades
            </label>

            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={unidades}
              value={unidades.find(x => x.value == unidade)}
              placeholder=''         
              onChange={async e => {
                setUnidade(e.value);
                
              }
              }
            />
          </div>
        </div>

                    </div>
                    </>
                    )}

                  

                    <h4 style={{marginTop: '40px', marginBottom: '1px'}}>INFORMAÇÕES DO(A) RESPONSÁVEL LEGAL</h4>
                     <hr
  style={{
    background: '#000',
    color: '#000',
    borderColor: '#00',
    height: '1px',
  }}
/>

<div className="row" style={{marginTop: 20}}>
<div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="responsavelNomeCompleto"
                    label="Nome Completo"
                    required={true}
                    />
                </div>
                <div className={"col-md-4"}>
                    <FormInput
                    register={register}
                    errors={errors}
                    type='date'
                    atribute="responsavelDataNascimento"
                    label="Data Nascimento"
                    required={true}
                    />
                </div>

                {/* {
            exibeEstudanteResponsavelLegal == true  && (
              
              <>
               <div className='col-md-2'>
            <div className="form-group">
              <label htmlFor="responsavelEstudante">Estudante?<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select  className="form-select" id="responsavelEstudante"
                  {...register("responsavelEstudante", {required: true })
            }>
                <option value=''></option>
                <option value={1}>SIM</option>
                <option value={0}>NÃO</option>
              </select>
             
            </div>
          </div>
          

          </>
            )
            } */}

                <div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="responsavelCpf"
                    mask="999.999.999-99"
                    label="CPF"
                    required={true}
                    />
                </div>
    </div>
    <div className="row" style={{marginTop: 20}}>
    <div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="responsavelRg"
                    label="RG"
                    required={true}
                    />
                </div>
                <div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="responsavelEmail"
                    type='email'
                    label="Email"
                    required={true}
                    />
                </div>
                <div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="responsavelTelefone"
                    mask="(99)99999-9999"
                    label="Telefone"
                    required={true}
                    />
                </div>
    </div>

    <div className="row" style={{marginTop: 20}}>
    <div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="reponsavelParentesco"
                    label="Parentesco"
                    required={true}
                    />
                </div>

                <div className='col-md-4'>
            <div className="form-group">
              <label htmlFor="responsavelDeficiente">Possui Alguma Deficiência??<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select className="form-select" id="responavelDeficiente" {...register("responsavelDeficiente", { required: true, valueAsNumber: true })} defaultValue={0}>
                <option value={0}></option>
                <option value={1}>SIM</option>
                <option value={2}>NÃO</option>
              </select>
             
            </div>
          </div>

          {
            watch('responsavelDeficiente') === 1  && (
              
              <>
                <div className='col-md-4'>
            <div className="form-group">
                    <label htmlFor="responsavelDeficiencia">Deficiência<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                    <select className="form-select" id="responsavelDeficiencia" {...register("responsavelDeficiencia", { required: watch('deficiente') === 1 ? true : false, valueAsNumber: true })} defaultValue={0}>
                    {deficiencias.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.text}
                    </option>
                    ))}
              </select>
                   
            </div>
          </div>
          

          </>
            )
            }

{
            watch('responsavelDeficiente') === 1  && (
              
              <>

              <div className="row" style={{marginTop: 20, color: 'red', }}>
        <h2>Atenção:</h2>
          <p>
          Necessário comprovação de laudo médico atualizado que comprove deficiência (no ato de efetivação da matrícula).
          </p>
          </div>
          </>
            )
            }

    </div>


    {/* {
            exibeAvisoComprovanteCurso === 1  && (
              
              <>

    <div className="row" style={{marginTop: 20, color: 'red', }}>
      <label><b>O responsável legal deverá apresentar declaração de cursando ao efetivar a matrícula do seu filho(a).</b></label>
    </div>

    </>
    )} */}

    <h4 style={{marginTop: '40px', marginBottom: '1px'}}>ENDEREÇO</h4>
                     <hr
  style={{
    background: '#000',
    color: '#000',
    borderColor: '#00',
    height: '1px',
  }}
/>

<div className="row" style={{marginTop: 20}}>

<div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="cep"
                    mask="99999-999"
                    label="CEP"
                    required={true}

                    />
                </div>
                <div className="col-md-2">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="estado"
                    label="Estado"
                    readOnly={true}
                    required={true}
                    />
                </div>
                <div className="col-md-3">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="cidade"
                    label="Cidade"
                    readOnly={true}
                    required={true}
                    />
                </div>

                <div className="col-md-3">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="bairro"
                    label="Bairro"
                    readOnly={true}
                    required={true}
                    />
                </div>

             
</div>

<div className="row" style={{marginTop: 20}}>
<div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="logradouro"
                    label="Logradouro"
                    readOnly={true}
                    required={true}
                    />
                </div>
                <div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="numero"
                    label="Numero"
                    required={true}
                    />
                </div>
                <div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="complemento"
                    label="Complemento"
                    required={false}
                    />
                </div>

</div>
{
  exibeAvisoEndereco == 1 &&(
    <>
<div className='row' style={{marginTop: 20}}>

<label style={{color: 'red'}}>O CEP informado não pertence ao município de São Pedro da Aldeia.</label>
</div>
</>
  )
}


<h4 style={{marginTop: '40px', marginBottom: '1px'}}>OUTRAS INFORMAÇÕES RELEVANTES</h4>
                     <hr
  style={{
    background: '#000',
    color: '#000',
    borderColor: '#00',
    height: '1px',
  }}
/>



                    

<div className='row' style={{marginTop: 20}}>

<div className='col-md-4'>
            <div className="form-group">
              <label htmlFor="beneficiarioProgramaSocial">Beneficiário do Bolsa Família ou cadastro no CadÚnico?<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select className="form-select" id="beneficiarioProgramaSocial" {...register("beneficiarioProgramaSocial", { required: true, valueAsNumber: true })} defaultValue={0}>
                <option value={0}></option>
                <option value={1}>BOLSA FAMÍLIA</option>
                <option value={2}>CADÚNICO</option>
                <option value={3}>NÃO SOU BENEFICIÁRIO DE PROGRAMAS SOCIAIS</option>
              </select>
              
            </div>
          </div>
         
{ exibeTempoTrabalho == 1 &&
(
<div className='col-md-3'>
            <div className="form-group">
              <label htmlFor="tempoTrabalho">Responsável Legal Está Empregado?<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select className="form-select" id="tempoTrabalho" {...register("tempoTrabalho", { required: true, valueAsNumber: true })} defaultValue={0}>
              {tempoTrabalho.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.text}
                    </option>
                    ))}
              </select>
              
            </div>
          </div>
)}
          

          <div className='col-md-5'>
            <div className="form-group">
              <label htmlFor="monoparental">Possui Filiação MonoParental? (quando só possui um dos genitores na certidão de nascimento)<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select className="form-select" id="monoparental" {...register("monoparental", { required: true, valueAsNumber: true })} defaultValue={0}>
              {tempoTrabalho.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.text}
                    </option>
                    ))}
              </select>
              
            </div>
          </div>

         

          
          
</div>

{ exibeAvisoBeneficios == 1 && (
                  <>

                  <div className="row" style={{marginTop: 20, color: 'red', }}>
            <h2>Atenção:</h2>
              <p>
              Necessário apresentar comprovante de CadÚnico ou do Bolsa Família (no ato de efetivação da matrícula).
              </p>
              </div> 
              </>
          )
}

{
            exibeDocumentosComprovante === 1  && (
              
              <>

    <div className="row" style={{marginTop: 20, color: 'red', }}>

    <h2>Atenção:</h2>
  <p>
  O <b>Responsável Legal que Está Empregado</b>, deverá apresentar um dos documentos listados abaixo <b>Na Unidade Escolar</b> (no ato da efetivação da mátricula)
  </p>
    <ul>
    <li>CTPs</li>
    <li>Contracheque</li>
    <li>Relação Anual de Informações Sociais (RAIS)</li>
    <li>Holerites e Recibos (assinados)</li>
    <li>Declaração de Imposto de Renda</li>
    <li>Extrato Previdenciário (CNIS)</li>
    <li>Comprovante de Autônomo</li>
    <li>MEI (Extrato)</li>
    <li>Contrato de Prestação de Serviço</li>
    <li>Declaração Comprobatória de Percepção de Rendimentos (DECORE)</li>
    <li>Declaração de Associação, Sindicato ou Entidade de Classe</li>
    <li>Declaração de Serviço Prestado pelo empregador com firma reconhecida. </li>
    
    <p style={{marginTop: 10}}>Todo o período de trabalho informado deverá ser devidamente comprovado, e não poderá ter data superior a 90 dias.</p>
</ul>
    </div>

    </>
    )}



<h4 style={{marginTop: '40px', marginBottom: '1px'}}>UNIDADES</h4>
<hr
  style={{
    background: '#000',
    color: '#000',
    borderColor: '#00',
    height: '1px',
  }}
/>

<div className="row" style={{marginTop: 20}}>
                    <div className="col-md-4">
              <div className="form-group">
                <label>Opção 1?<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                <select  className="form-select" id="opcao1"
                 {...register("opcao1", { valueAsNumber: true, required: true })
            } defaultValue={0}>
                <option></option>
                {dataUnidades?.map(option => (
                    <option key={option.id} value={option.id}>
                        {option.nome}
                    </option>
                    ))}
                    
                </select>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label>Opção 2?</label>
                <select  className="form-select" id="opcao2" {...register("opcao2", { valueAsNumber: true })} defaultValue={0}>
                <option value={0}></option>
                {dataUnidades?.map(option => (
                    <option key={option.id} value={option.id}>
                        {option.nome}
                    </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label>Opção 3?</label>
                <select  className="form-select" id="opcao3" {...register("opcao3", { valueAsNumber: true })} defaultValue={0}>
                <option value={0}></option>
                {dataUnidades?.map(option => (
                    <option key={option.id} value={option.id}>
                        {option.nome}
                    </option>
                    ))}
                </select>
              </div>
            </div>
            

                    </div>

                    <div className='row' style={{marginTop: 20}}>
<div className='col-md-4'>
            <div className="form-group">
              <label htmlFor="possuiIrmao">Possui irmão/irmã na primeira escola pretendida?<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
              <select className="form-select" 
              id="possuiIrmao" {...register("possuiIrmao", { required: true, valueAsNumber: true })} defaultValue={0}>
              <option value={0}></option>
                <option value={1}>SIM</option>
                <option value={2}>NÃO</option>
              </select>
              
            </div>
          </div>
          {
            watch('possuiIrmao') === 1  && (
              
              <>
                <div className='col-md-4'>
            <div className="form-group">
                    <label htmlFor="escolaridadeIrmao">Escolaridade<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                    <select className="form-select" id="escolaridadeIrmao" {...register("escolaridadeIrmao", { required: watch('deficiente') === 1 ? true : false, valueAsNumber: true })} defaultValue={0}>
                    {escolaridades?.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.text}
                    </option>
                    ))}
              </select>
                   
            </div>
          </div>
          

          </>
            )
            }

{
            watch('possuiIrmao') === 1  && (
              
              <>
               <div className="col-md-4">
                    <FormInput
                    register={register}
                    errors={errors}
                    atribute="nomeIrmao"
                    label="Nome"
                    required={true}
                    />
                </div>
          

          </>
            )
            }


            
    
</div>

<div className="row" style={{ marginTop: 30 }}>
          <hr />
          <div className='col-md-12'>
            <label htmlFor="termoDeclaracao">
              {/* <h4>Termo de declaração e ciência:</h4> */}
              <p>Realizei a leitura e concordo com os <a href='/assets/RESOLUCOES SEMED_011_2023_0000001.pdf' target="_blank">termos da resolução</a>.*</p></label>

            <div className='col-md-12' style={{ display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" id="termoDeclaracao" name="termoDeclaracao" style={{ marginRight: '12px', width: '20px', height: '20px' }} {...register('termoDeclaracao', { required: true, valueAsNumber: true })} />
              <label for="termoDeclaracao">Declaro que estou ciente</label>
            </div>
          </div>
          {errors?.termoDeclaracao?.type === 'required' && (
            <span className="text-danger" style={{ marginTop: '8px' }} >Concorde com os termos para enviar o formulário.</span>
          )}
        </div>

       
<button type="submit" className="btn btn-success" style={{marginTop: '20px', display: exibeAvisoEndereco === 1 || exibeAvisoCpf === 1 ? 'none' : 'initial'}}>
                    Enviar
                  </button>
                  <button className="btn btn-warning" 
                      style={{marginTop: '20px', marginLeft: exibeAvisoEndereco === 1 ? '0px' : '20px'}} 
                      onClick={() => navigate('/chamada-publica')}>
                    Voltar
                  </button>


                </div>
                

                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChamadaPublicaFormularioPage
