import Breadcrumb from '../../../../../components/breadcrumb'
import BaseChamadaPublica from '../../baseChamadaPublica'
import FormInput from '../../../../../components/formInput'
import { useState } from 'react'
import { pesquisaNome } from '../../../../../services/processos/chamadaPublica'
import Table from '../../../../../components/table'
import { useMemo, useEffect } from 'react'
import { useQuery } from 'react-query';
import urlsChamadaPublica from '../../urlsChamadaPublica'


const ChamadaPublicaPesquisarPage = () => {

    const [nome, setNome] = useState('')
    const [data, setData] = useState([])
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(20);


  

  const { isError, isLoading, refetch } = useQuery(
    'pesquisaNome',
    () => pesquisaNome(nome, skip,limit),
    {
      retry: 0,
      enabled: true,
      onSuccess: data => {
        console.log(data)
        setData(data)
      }
    }
  )

    // async function handleSearch(){
    //     const response = await pesquisaNome(nome, skip, limit)
    //     setData(response)
    // }

    useEffect(() => {

      if(nome.length > 3){
        refetch()
      }


  
    }, [refetch, nome, limit, skip])

    function abrirInscricao(id){
        localStorage.setItem('ID', id)
        window.open(urlsChamadaPublica.visualizacao + id, '_blank')
    
      }

    const columns = useMemo(
        () => [
          { Header: '#', accessor: 'id' },
          { Header: 'Unidade', accessor: 'unidade' },
          { Header: 'Opção', accessor: 'opcao' },
          { Header: 'Estudante', accessor: 'nome' },
          { Header: 'CPF', accessor: 'cpf' },
          {
            Header: 'Status', accessor: 'status',
            Cell: ({ row }) => (
              <span className={`badge bg-dark`}>
                {row.original.status}
              </span>
            )
          },
          {
            Header: '',
            accessor: 'actions',
            Cell: ({ row }) => (
              <>
    
    <button
                  onClick={() =>
                    {  abrirInscricao(row.original.id)}
                    // navigate(urls.processosSeletivosVInscricao + row.original.id)
                  }
                  className="btn btn-sm btn-primary"
                >
                  <i className="material-icons-two-tone">edit_note</i>
                </button>
                
               
                  
               
                
              </>
            )
          },
        ])
   
    



return (
    <BaseChamadaPublica>
       <Breadcrumb title={'PESQUISAR NOME'} itens={['CHAMADA PÚBLICA', 'INSCRIÇÕES', 'PESQUISAR']} />
       {/* <form id="form" onSubmit={onsubmit()}> */}
      <div className="card-body">
      {/* <div className='row'>
                    <div className='col-8'>
                      <label htmlFor="nome" className="form-label">Nome</label>
                      <input type="text" className="form-control" id="nome"
                      />
                      
                    </div>
                    <div className='col-4'>
                        <button></button>
                    </div>
                  </div> */}

<div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          marginTop: 10,
          marginBottom: 20
        }}
      >

        <div className="form-group" style={{ width: '100%', marginRight: 0 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Nome do Aluno"
            value={nome}
            onChange={e => setNome(e.target.value)}
          />
        </div>

      
        <div
          className="form-group"
          style={{
            marginTop: 6,
            marginLeft: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          {/* <button
            className="btn btn-sm btn-primary"
            onClick={() => refetch()}
          >
            <i className="material-icons-two-tone">search</i> Buscar
          </button> */}
        </div>
        </div>

        <div className="row">
        <div className="col-12">
          <div className="card">
            <Table
              columns={columns}
              data={data?.data ? data?.data : []}
              hiddenColluns={['cpf']}
              hasPagination={true}
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={data?.total}
            />
          </div>
        </div>
      </div>


        </div>
        {/* </form> */}
       
      </BaseChamadaPublica>
)
}

export default ChamadaPublicaPesquisarPage