import urlsChamadaPublica from "./urlsChamadaPublica";

const linksChamadaPublica = {

  chama_publica: {
    id: 'chama_publica',
    text: 'Chamada Pública',
    type: 'title',
    visible: true,
    itens: {
      dashboard: {
        id: 'dashboard',
        text: 'DASHBOARD',
        path: urlsChamadaPublica.dashboard,
        icon: 'dashboard',
        visible: true,
      },
      inscricoes_creche: {
        id: 'inscricoes_creche',
        text: 'CRECHE',
        path: urlsChamadaPublica.creches,
        icon: 'inbox',
        visible: true,
      },
      inscricoes_pre: {
        id: 'inscricoes_pre',
        text: 'PRÉ - 9 ANO',
        path: urlsChamadaPublica.pre,
        icon: 'inbox',
        visible: true,
      },
      pesquisar: {
        id: 'pesquisar',
        text: 'PESQUISAR',
        path: urlsChamadaPublica.pesquisar,
        icon: 'search',
        visible: true,
      },
    },    
},
convocacao: {
  id: 'convocacao',
  text: 'CONVOCAÇÃO',
  type: 'title',
  visible: true,
  itens: {
    inscricoes_creche: {
      id: 'inscricoes_creche',
      text: 'CRECHE',
      path: urlsChamadaPublica.crechesConvocados,
      icon: 'inbox',
      visible: true,
    },
    inscricoes_pre: {
      id: 'inscricoes_pre',
      text: 'PRÉ - 9 ANO',
      path: urlsChamadaPublica.preConvocados,
      icon: 'inbox',
      visible: true,
    },
  },
  
},
remanejamento: {
  id: 'remanejamento',
  text: 'REMANEJAMENTO',
  type: 'title',
  visible: true,
  itens: {
    inscricoes_creche: {
      id: 'inscricoes_creche',
      text: 'CRECHE',
      path: urlsChamadaPublica.crechesRemanejamento,
      icon: 'inbox',
      visible: true,
    },
    inscricoes_pre: {
      id: 'inscricoes_pre',
      text: 'PRÉ - 9 ANO',
      path: urlsChamadaPublica.preRemanejamento,
      icon: 'inbox',
      visible: true,
    },
  },
  
},
inscritos: {
  id: 'inscritos',
  text: 'Inscritos',
  type: 'title',
  visible: true,
  itens: {
    unidade: {
      id: 'unidade',
      text: 'POR UNIDADE',
      path: urlsChamadaPublica.relatorioInscritosPorUnidade,
      icon: 'language',
      visible: true,
    },
    unidade_nominal: {
      id: 'unidade_nominal',
      text: 'POR UNIDADE - NOMINAL',
      path: '',
      icon: 'language',
      visible: false,
    },
    escolaridade: {
      id: 'escolaridade',
      text: 'POR ESCOLARIDADE',
      path: urlsChamadaPublica.relatorioInscritosPorEscolaridade,
      icon: 'language',
      visible: true,
    },
    bairro: {
      id: 'bairro',
      text: 'POR BAIRRO',
      path: urlsChamadaPublica.relatorioInscritorPorBairro,
      icon: 'language',
      visible: true,
    },
    pne: {
      id: 'pne',
      text: 'POR PCD',
      path: urlsChamadaPublica.relatorioInscritosPorPne,
      icon: 'language',
      visible: true,
    },
    transferencia_externa: {
      id: 'transferencia_externa',
      text: 'POR TRANS. EXTERNA',
      path: urlsChamadaPublica.relatorioInscritosTransferenciaExterna,
      icon: 'language',
      visible: true,
    },
    transferencia_interna: {
      id: 'transferencia_interna',
      text: 'POR TRANS. INTERNA',
      path: urlsChamadaPublica.relatorioInscritosTransferenciaInterna,
      icon: 'language',
      visible: true,
    },
    periodo_quantitativo: {
      id: 'periodo_quantitativo',
      text: 'PERÍODO - QUANTITATIVO',
      path: '',
      icon: 'language',
      visible: false,
    },
    novos_da_rede: {
      id: 'novos_da_rede',
      text: 'NOVOS DA REDE',
      path: urlsChamadaPublica.relatorioInscritosPorNovosDaRede,
      icon: 'language',
      visible: false,
    },
    geral: {
      id: 'geral',
      text: 'GERAL',
      path: urlsChamadaPublica.relatorioInscritosPorGeral,
      icon: 'language',
      visible: true,
    },
  }
},

convocados: {
  id: 'convocados',
  text: 'CONVOCADOS',
  type: 'title',
  visible: true,
  itens: {
    escolaridade: {
      id: 'escolaridade',
      text: 'POR ESCOLARIDADE',
      path: urlsChamadaPublica.relatorioConvocacoesPorEscolaridade,
      icon: 'language',
      visible: true,
    },
    periodo_quantitativo: {
      id: 'periodo_quantitativo',
      text: 'PERÍODO - QUANTITATIVO',
      path: urlsChamadaPublica.relatorioMatriculadosPorPeriodoQuantitativo,
      icon: 'language',
      visible: true,
    },
    periodo_nominal: {
      id: 'periodo_nominal',
      text: 'PERÍODO - NOMINAL',
      path: urlsChamadaPublica.relatorioMatriculadosPorPeriodoNominal,
      icon: 'language',
      visible: true,
    },
  
  }
},
matriculados: {
  id: 'matriculados',
  text: 'MATRICULADOS',
  type: 'title',
  visible: true,
  itens: {
    unidade: {
      id: 'unidade',
      text: 'POR UNIDADE',
      path: urlsChamadaPublica.relatorioMatriculadosPorUnidade,
      icon: 'language',
      visible: true,
    },
    escolaridade: {
      id: 'escolaridade',
      text: 'POR ESCOLARIDADE',
      path: urlsChamadaPublica.relatorioMatriculadosPorEscolaridade,
      icon: 'language',
      visible: true,
    },
    periodo_quantitativo: {
      id: 'periodo_quantitativo',
      text: 'PERÍODO - QUANTITATIVO',
      path: urlsChamadaPublica.relatorioMatriculadosPorPeriodoQuantitativo,
      icon: 'language',
      visible: true,
    },
    periodo_nominal: {
      id: 'periodo_nominal',
      text: 'PERÍODO - NOMINAL',
      path: urlsChamadaPublica.relatorioMatriculadosPorPeriodoNominal,
      icon: 'language',
      visible: true,
    },
    geral: {
      id: 'geral',
      text: 'GERAL',
      path: urlsChamadaPublica.relatorioMatriculadosPorGeral,
      icon: 'language',
      visible: true,
    },
    
  
  }
},

relatorios: {
  id: 'relatorios',
  text: 'Relatórios',
  type: 'title',
  visible: false,
  itens: {
    rede: {
      id: 'rede',
      text: 'LISTA ESPERA',
      path: urlsChamadaPublica.listaEspera,
      icon: 'book',
      visible: true,
    },
    externa: {
      id: 'externa',
      text: 'TRANS. EXTERNA',
      path: urlsChamadaPublica.relatoriosTransferenciasExternas,
      icon: 'sync_alt',
      visible: true,
    },
    inscritos: {
      id: 'inscritos',
      text: 'INSCRITOS',
      path: urlsChamadaPublica.relatoriosInscritos,
      icon: 'language',
      visible: true,
    },
    matriculados: {
      id: 'matriculados',
      text: 'MATRICULADOS',
      path: urlsChamadaPublica.relatoriosMatriculados,
      icon: 'star',
      visible: true,
    },
    vagas: {
      id: 'vagas',
      text: 'SALDO DE VAGAS',
      path: urlsChamadaPublica.relatorioSaldoVagas,
      icon: 'report',
      visible: true,
    },
}
},
formulario: {
  id: 'formulario',
  text: 'FORMULARIO',
  type: 'title',
  visible: true,
  itens: {
    unidade: {
      id: 'unidade',
      text: 'FORMULÁRIO',
      path: urlsChamadaPublica.homeFormulario,
      icon: 'inbox',
      visible: true,
    },
  }
},
};

export default linksChamadaPublica;