import { useMemo, useState, useEffect } from 'react';
import Breadcrumb from '../../../../../components/breadcrumb';
import Table from '../../../../../components/table';
import BaseGestaoUnidades from '../../../../gestao-unidades/baseGestaoUnidades';
import urlsGestaoUnidades from '../../../../gestao-unidades/urlsGestaoUnidades';
import urlsChamadaPublica from '../../urlsChamadaPublica';
import { useNavigate } from "react-router-dom";
import { listarMatriculas } from '../../../../../services/processos/chamadaPublica';

import { useQuery, useMutation } from 'react-query';
import Spinner from '../../../../../components/spinner';
import moment from 'moment';

const ChamadaPublicaMatriculas = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [data, setData] = useState([]);
  const [nome, setNome] = useState('');
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const unidadeId = JSON.parse(localStorage.getItem('unidadeID'));

  const { isError, isLoading, refetch } = useQuery(
    'listarInscricoes',
    () => listarMatriculas(unidadeId, skip,limit, nome),
    {
      retry: 0,
      enabled: true,
      onSuccess: data => {
        console.log(data)
        setData(data)
      }
    }
  )


  useEffect(() => {
    if (nome.length >= 3) {
      setNome(nome)
      refetch()
    }
    refetch()
    // refetchEscolaridades()
  }, [refetch, limit, skip, nome])

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome', accessor: 'nome' },
      {
        Header: 'Remanejamento?', accessor: 'opcaoAlternativa',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.opcaoAlternativa == unidadeId ? 'success' : 'danger'}`}>
            {row.original.opcaoAlternativa == unidadeId ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      {
        Header: 'Data Nascimento', accessor: 'dataNascimento',
        Cell: ({ row }) => {
          const nascimento = row.original.dataNascimento
          const data = nascimento ? moment(((nascimento).split('T'))[0]).format('DD/MM/YYYY') : ''
          return (
            <>
              {data}
            </>
          )
        }
      },
      {
        Header: 'PcD', accessor: 'deficiente',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.deficiente == 1 ? 'success' : 'danger'}`}>
            {row.original.deficiente == 1 ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      {
        Header: 'Estudante da Rede?', accessor: 'estudanteDaRede',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.estudanteDaRede == 1 ? 'success' : 'danger'}`}>
            {row.original.estudanteDaRede == 1 ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      { Header: 'Escolaridade', accessor: 'escolaridadeDescricao' },
    //   { Header: 'Turno', accessor: 'turnoID' },
    //   { Header: 'CPF', accessor: 'cpf' },
      {
        Header: 'Data Inscrição', accessor: 'criacao',
        Cell: ({ row }) => {
          const nascimento = row.original.criacao
          const data = nascimento ? moment(((nascimento).split('T'))[0]).format('DD/MM/YYYY') : ''
          return (
            <>
              {data}
            </>
          )
        }
      },
    //   { Header: 'Responsável', accessor: 'responsavel.nome' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
          <button
              onClick={() =>
                {  window.open(urlsChamadaPublica.visualizacao + row.original.id, '_blank')}
                // navigate(urls.processosSeletivosVInscricao + row.original.id)
              }
              className="btn btn-sm btn-dark"
            >
              <i className="material-icons-two-tone">edit_note</i> visualizar
            </button>
          
            <button onClick={() => navigate(urlsGestaoUnidades.matriculaEnturmar + row.original.id, {
              state: {
                id: row.original.id,
                aluno: {
                  nome: row.original.nome,
                  cpf: row.original.cpf,
                  escolaridadeId: row.original.escolaridade,
                }
              }
            })} className='btn btn-sm btn-primary'>
              <i className="material-icons-two-tone">add</i> enturmar
            </button>
            {` `}
            <button onClick={() => navigate(urlsGestaoUnidades.matriculasIndeferir, {
               state: {
                id: row.original.id,
                remanejamento: row.original.opcaoAlternativa == unidadeId ? true : false,
                aluno: {
                  nome: row.original.nome,
                  cpf: row.original.cpf,
                  escolaridadeId: row.original.escolaridadeID,
                  turnoId: row.original.turnoID
                }
              }
            })} className='btn btn-sm btn-danger'>
              <i className="material-icons-two-tone">group_remove</i> indeferir
            </button>
          </>
        )
      },
    ],
    [navigate]
  )

  const getTableData = () => {
    if (isError) {
      return (
        <div className='card-body'>
          <h5 className='card-title'>Erro ao carregar dados</h5>
          <p className='card-text'>Não foi possível carregar os dados. Tente novamente mais tarde.</p>
        </div>
      )
    }
    if (isLoading || !data) {
      return <Spinner />
    }
    return  <Table
    columns={columns}
    data={data?.data ? data.data : []}
    hasPagination
    limit={limit}
    setLimit={setLimit}
    skip={skip}
    setSkip={setSkip}
    totalItems={data?.total}
    // hiddenColluns={["escolaridade"]}
  />
  }

  return (
    <BaseGestaoUnidades>
      <Breadcrumb title={'Matrículas'} itens={['Gestão de Unidades', 'Matrículas', 'Lista Geral']} />
      <div className="col-12 col-md-12" style={{marginTop : '10px'}}>
        <div className='form-group'>
        {/* <label htmlFor="regimeId" className="form-label">
              PESQUISAR
            </label> */}
          <input
            type='text'
            className='form-control' 
            placeholder='DIGITE O NOME DO ALUNO'
            value={nome}
            onChange={e => setNome(e.target.value)}
          />
        </div>
        </div>
      <div className='row' style={{marginTop: '20px'}}>
        <div className='col-12'>
          <div className='card'>
            {getTableData()}
          </div>
        </div>
      </div>
    </BaseGestaoUnidades>
  )

};

export default ChamadaPublicaMatriculas;
