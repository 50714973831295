import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../../../../../components/breadcrumb";
import BaseGestaoUnidades from "../../../../../gestao-unidades/baseGestaoUnidades";
import urlsGestaoUnidades from "../../../../../gestao-unidades/urlsGestaoUnidades";


import { getEscolaridadesUnidade } from "../../../../../../services/escolaridades";
import { getTurnosUnidadeEscolaridade } from "../../../../../../services/turnos";
import { getTurmasUnidadeEscolaridadeTurno } from '../../../../../../services/turmas';

import { postEnturmar } from "../../../../../../services/processos/chamadaPublica";
import { useQuery, useMutation } from 'react-query';
import removeEmpty from "../../../../../../utils/removeEmpty";
import Table from "../../../../../../components/table";
import upperCaseSimple from "../../../../../../utils/upperCaseSimple";
import { store } from "../../../../../../store";

const MatriculasEnturmar = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [aluno, setAluno] = useState(null);
  const [responsavel, setResponsavel] = useState(null);
  const [cpf, setCpf] = useState(null);
  const [unidade, setUnidade] = useState(null);
  const [escolaridade, setEscolaridade] = useState(null);
  const [turno, setTurno] = useState(null);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);

  const [dataTurno, setDataTurno] = useState([]);
  const [dataTurma, setDataTurma] = useState([]);

  const [loadingTurnos, setLoadingTurnos] = useState([])
  const [loadingTurmas, setLoadingTurmas] = useState([])

  const currentUser = store.getState()['user']['user']
  

  const { data: unidades, isLoading: loadingEscolaridades } = useQuery('getEscolaridades', () => unidade !== null ? getEscolaridadesUnidade(unidade) : null, {
    retry: 3,
    enabled: unidade !== null,
  });

  // const { data: turnos, isLoading: loadingTurnos, refetch: refetchTurnos } = useQuery('getTurnos', () => unidade !== null && escolaridade !== null ? getTurnos(unidade, escolaridade) : null, {
  //   retry: 3,
  //   enabled: unidade !== null && escolaridade !== null,
  // });

  // const { data: turmas, isLoading: loadingTurmas, refetch: refetchTurmas } = useQuery('getTurmas', () => unidade !== null && escolaridade !== null && turno !== null ? getTurmas(unidade, escolaridade, turno, 2024) : null, {
  //   retry: 3,
  //   enabled: unidade !== null && escolaridade !== null && turno !== null,
  // });

  const { mutate, isLoading } = useMutation(postEnturmar, {
    onSuccess: (message) => {
      toast.success(message);
      navigate(urlsGestaoUnidades.matriculas);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar enturmar, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const { register, handleSubmit, setValue, formState: { isValid, errors } } = useForm({
    mode: "onChange", defaultValues: {
      alunoID: '',
      unidadeID: '',
      escolaridadeID: '',
      turnoID: '',
      turmaID: '',
      usuarioID: currentUser?.servidorId,
    }
  });

  // const { isError, refetch } = useQuery(
  //   'getTurmas', () => getTurmas(unidade, escolaridade, turno, 2024, skip, limit),
  //   {
  //     retry: 0,
  //     enabled: false,
  //     onSuccess: (data) => {
  //       setTurmasData(data);
  //     }
  //   });

  const onSubmit = (data) => {
    mutate(upperCaseSimple(removeEmpty(data)));
  };

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome Turma', accessor: 'descricao' },
      //{ Header: 'Vagas', accessor: 'vagas' },
      { Header: 'Vagas Totais' , accessor: 'vagasTotais' },
      { Header: 'Escolaridade' , accessor: 'escolaridadeId' },
      { Header: 'Turno' , accessor: 'turnoId' },
      // { Header: 'Alunos', accessor: 'quantidadeAlunos' },
      // { Header: 'Sala (m²)', accessor: 'metragemSala' },
      // { Header: 'Ano Letivo', accessor: 'anoLetivo' },
      { Header: 'Vagas Regulares', accessor: 'vagas' },
      { Header: 'Vagas PcD', accessor: 'vagasPcd' },
      { Header: 'Alunos Regulares', accessor: 'quantidadeAlunosRegulares' },
      { Header: 'Alunos PcD', accessor: 'quantidadeAlunosPcdPne' },
      //{ Header: 'Pre Matricula', accessor: 'preMatricula' },
      {
        Header: 'Saldo',
        accessor: 'saldo',
        Cell: ({ row }) => {
          const vagas = row.original.vagasTotais;
          const alunosRegulares = row.original.quantidadeAlunosRegulares;
          const alunosPcdPne = row.original.quantidadeAlunosPcdPne;
  
          const saldo = vagas - (alunosRegulares + alunosPcdPne * 3);
  
          return (
            <span style={{
              color: saldo < 0 ? 'red' : 'inherit',
              fontWeight: saldo < 0 ? 'bold' : 'normal',
            }}>
              {saldo}
            </span>
          );
        },
      },
      { Header: 'PreMatricula', accessor: 'preMatricula' },
    ],
    [navigate]
  )

  async function fetchTurno(unidade, escolaridade){
    if(unidade != 0 && escolaridade != 0){
      var response = await getTurnosUnidadeEscolaridade(unidade, escolaridade)
      setLoadingTurnos(false)
      setDataTurno(response || [])
    }
  }

  async function fetchTurma(unidade, escolaridade, turno){
    if(unidade != 0 && escolaridade != 0 && turno != 0){
      var response = await getTurmasUnidadeEscolaridadeTurno(unidade, escolaridade, turno, 2024)
      console.log(response)
      setLoadingTurmas(false)
      setDataTurma(response || [])
    }
  }

  useMemo(() => {
    (async () => {
      if (state) {
        const { aluno } = state;
        if (id && aluno) {
          console.log(aluno)
          const unidadeID = await localStorage.getItem('unidadeID');
          const { nome, responsavel, cpf, escolaridadeId } = aluno;
          await fetchTurno(unidadeID, escolaridadeId)
          setValue('alunoID', id);
          setValue('unidadeID', unidadeID);
          setValue('escolaridadeID', escolaridadeId)
          setUnidade(unidadeID);
          setEscolaridade(escolaridadeId)
          // refetchTurnos()
          setAluno(nome);
          setResponsavel(responsavel);
          setCpf(cpf);
        }
      }
    })();
  }, [id, state, setValue, setAluno, setResponsavel, setUnidade]);

  return (
    <BaseGestaoUnidades>
      <Breadcrumb title={'Enturmação'} itens={['Gestão de Unidades', 'Matrículas', 'Enturmação']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
    
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <>
                  <div className='row'>
                    <div className='col-12'>
                      <label htmlFor="nome" className="form-label">Nome</label>
                      <input type="text" className="form-control" id="nome" value={aluno} readOnly />
                    </div>
                  </div>

                  <br />

                  {/* <div className='row'>
                    <div className='col-12'>
                      <label htmlFor="cpf" className="form-label">CPF</label>
                      <input type="text" className="form-control" id="cpf" value={cpf} readOnly />
                    </div>
                  </div> */}

                  <br />

                  <div className='row'>
                    <div className='col-12'>
                      <label htmlFor="escolaridadeID" className="form-label">Escolaridades</label>
                      <select
                        className="form-select"
                        id="escolaridadeID"
                        value={escolaridade}
                        {...register('escolaridadeID', { required: true })}
                        onChange={async (e) => {
                          await setValue('escolaridadeID', e.target.value);
                          await setEscolaridade(e.target.value);
                          await setValue('turnoID', '');
                          await setValue('turmaID', '');
                          //refetchTurnos();
                          // refetchTurmas();
                        }}
                        disabled={true}
                      >
                        {
                          loadingEscolaridades ? (
                            <option value=''>Carregando...</option>
                          ) : (
                            <>
                              <option value=''>Selecione</option>
                              {unidades && unidades.map((item) => (
                                <option key={item.id} value={item.id}>{item.descricao}</option>
                              ))}
                            </>
                          )
                        }
                      </select>
                      {errors.escolaridadeID && <span className='text-danger'>Campo obrigatório</span>}
                    </div>
                  </div>

                  <br />

                  <div className='row'>
                    <div className='col-12'>
                      <label htmlFor="turnoID" className="form-label">Turnos</label>
                      <select
                        className="form-select"
                        id="turnoID"
                        {...register('turnoID', { required: true })}
                        onChange={async (e) => {
                          await setValue('turnoID', e.target.value);
                          await setTurno(e.target.value);
                          await setValue('turmaID', '');
                          fetchTurma(unidade, escolaridade, e.target.value)
                        }}>
                        {
                          loadingTurnos ? (
                            <option value=''>Carregando...</option>
                          ) : (
                            <>
                              <option value=''>Selecione</option>
                              {dataTurno && dataTurno.map((item) => (
                                <option key={item.id} value={item.id}>{item.descricao}</option>
                              ))}
                            </>
                          )
                        }
                      </select>
                      {errors.turnoID && <span className='text-danger'>Campo obrigatório</span>}
                    </div>
                  </div>

                  <br />

                  <div className='row'>
                    <div className='col-12'>
                      <label htmlFor="turmaID" className="form-label">Turmas</label>
                      <select
                        className="form-select"
                        id="turmaID"
                        {...register('turmaID', { required: true })}
                        onChange={async (e) => {
                          await setValue('turmaID', e.target.value);
                          
                        }}>
                        {
                          loadingTurmas ? (
                            <option value=''>Carregando...</option>
                          ) : (
                            <>
                              <option value=''>Selecione</option>
                              {dataTurma?.data.map((item) => (
                                <option key={item.id} value={item.id}>{item.descricao}</option>
                              ))}
                            </>
                          )
                        }
                      </select>
                      {errors.turmaID && <span className='text-danger'>Campo obrigatório</span>}
                    </div>
                  </div>

                </>
              </div>

              <div className='row'>
                    <div className='col-12'>
                    <div className='card'>
              <Table
                columns={columns}
                data={dataTurma?.data ? dataTurma?.data : []}
                hasPagination
                limit={dataTurma?.limit}
                setLimit={setLimit}
                skip={dataTurma?.skip}
                setSkip={setSkip}
                totalItems={dataTurma?.total}
                hiddenColluns={['preMatricula', 'escolaridadeId', 'turnoId']}
              />
            </div>
                      </div>
                      </div>

              <div className='card-footer text-end'>
                <button type='submit' className='btn btn-success'>
                  Enturmar
                </button>
              </div>

             
            </div>
          </div>
        </div>
      </form>
    </BaseGestaoUnidades>
  )

};

export default MatriculasEnturmar;